<template>
  <div class="root flex_center">
    <video
      ref="bgVideo"
      :src="minVideoUrl"
      poster='http://naae.oss-cn-hangzhou.aliyuncs.com/beliwin/meiyan_video_cover.png'
      autoplay
      style="width: 100vw; height: 100vh; object-fit: fill"
      loop="loop"
    ></video>
    <div class="swiper_con flex_center">
      <div
        class="circle_box flex_between_align_center"
        enter-active-class="animated fadeIn"
      >
        <div class="circle_con flex_center">
          <div class="flex_between_align_center con">
            <div class="text">
              <p class="english animate__animated animate__fadeInRight">
                STUDY
              </p>
              <p class="english animate__animated animate__fadeInRight">
                IN THE USA
              </p>
              <p class="chinese animate__animated animate__fadeInRight">
                随时随地链接海外名师
              </p>
            </div>
            <div class="search flex_center" @click="toPath('haishi')">
              <i class="iconfont xhgsearch"></i>
            </div>
            <div class="play_btn flex_center" @click="playVideo(videoUrl)">
              <i class="iconfont xhgvideo-play"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="mouse">
        <i class="iconfont xhgmouse relative">
          <i class="point"></i>
        </i>
      </div>
    </div>
    <VideoDialog
      ref="videoDialog"
      type="bgVideo"
      @pauseBgVideo="pauseBgVideo"
    ></VideoDialog>
  </div>
</template>
<script>
import VideoDialog from "../videoDialog";
import commonMeth from "../../commonMeth/index";

export default {
  name: "",
  components: {
    VideoDialog,
  },
  props: [""],
  data() {
    return {
      videoUrl:
        "http://naae.oss-cn-hangzhou.aliyuncs.com/beliwin/haishi_home.mp4",
      minVideoUrl:
        "http://naae.oss-cn-hangzhou.aliyuncs.com/beliwin/meiyan_home.mp4",
    };
  },
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    // 跳转页面
    toPath(val) {
      // 公共方法
      commonMeth.openNewTab({ name: val });
    },
    // 弹窗视频播放
    playVideo(url) {
      this.pauseBgVideo(true);
      this.$refs.videoDialog.show(url);
    },
    // 播放/暂停背景视频
    pauseBgVideo(val) {
      if (val) this.$refs.bgVideo.pause();
      else this.$refs.bgVideo.play();
    },
  },
  computed: {},
};
</script>
<style scoped lang="scss">
/* 包裹局部模糊图片的div */
.video-mask {
  /* 模糊的大小 */
  width: 400px;
  height: 400px;
  /* 图片超出盒子的隐藏，不会遮盖底图 */
  overflow: hidden;
  /* 定位到所需的位置 */
  position: absolute;
  top: 50%;
  left: 50%;
  /* 设置模糊 */
  // filter: blur(5px);
  /* Chrome, Safari, Opera */
  // -webkit-filter: blur(5px);
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
}

.video-mask video {
  /* 定位的top,left 刚好和外层盒子相反，使用于模糊的图片和底图的位置重合 */
  position: absolute;
  top: -50%;
  left: -50%;
}

.swiper_con {
  width: 1200px;
  height: 850px;
  z-index: 99;
  position: relative;
}
.mouse {
  position: absolute;
  bottom: 0;
  left: calc(50% - 18px);
  i {
    font-size: 40px;
    color: #fff;
  }
  /* 声明动画 关键帧 @keyframes 动画名称{} */
  @keyframes move {
    from {
      top: 14;
      opacity: 0.3;
      // background-color: pink;
    }
    to {
      top: 31px;
      opacity: 0.8;
      // background-color: yellow;
    }
  }
  @-webkit-keyframes move {
    from {
      top: 14;
      opacity: 0.3;
      // background-color: yellow;
    }
    to {
      top: 31px;
      opacity: 0.8;
      // background-color: yellow;
    }
  }
  @-ms-keyframes move {
    from {
      top: 14;
      opacity: 0.3;
      // background-color: pink;
    }
    to {
      top: 31px;
      opacity: 0.8;
      // background-color: yellow;
    }
  }
  .point {
    width: 3px;
    height: 3px;
    background: #fff;
    position: absolute;
    left: 19px;
    top: 14px;
    border-radius: 50%;
    /*调用动画 */
    /* animation：动画名称 花费时间 运动曲线 何时开始 播放次数 是否反方向 */
    animation: move 1.5s ease 0s infinite alternate;
    -webkit-animation: move 1.5s ease 0s infinite alternate;
    // top:31px;
  }
}
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex_between_align_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle_box {
  width: 505px;
  height: 505px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  backdrop-filter: blur(5px);
  position: relative;
  .circle_con {
    position: absolute;
    top: 0;
    width: 505px;
    height: 505px;
    .con {
      width: 100%;
      position: relative;
    }
    // background: rgba(0, 0, 0, 0.15);
    // border-radius: 50%;
    .back {
      position: absolute;
      top: 0;
      width: 505px;
      height: 505px;
      background: rgba(0, 0, 0, 0.15);
      filter: blur(4px);
      overflow: hidden;
      video {
        position: absolute;
        left: 0px;
      }
    }
    .play_btn {
      width: 120px;
      height: 48px;
      border-radius: 24px;
      border: 2px solid #fff;
      margin: 63px auto 0;
      cursor: pointer;
      position: absolute;
      bottom: -70px;
      left: calc(50% - 60px);
      &:hover {
        // #1890ff
        border: 2px solid #1890ff;
        i {
          color: #1890ff;
        }
      }
      i {
        font-size: 22px;
        color: #fff;
      }
    }
  }
  .text {
    margin-left: 66px;
    margin-bottom: 30px;
  }
  .english {
    font-size: 50px;
    font-weight: bold;
    color: #ffffff;
    line-height: 70px;
  }
  .chinese {
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    line-height: 39px;
    letter-spacing: 3px;
    margin-top: 10px;
  }
  // filter: blur(5px);
}
.root {
  // background: url(./image/bg.png) no-repeat;
  background-size: cover;
  height: 100%;
  video {
    position: absolute;
    top: 0;
  }
}

.search {
  width: 124px;
  height: 124px;
  background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
  border-radius: 50%;
  margin-right: -62px;
  cursor: pointer;
  i {
    color: #fff;
    font-size: 50px;
  }
}
</style>
