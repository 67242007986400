<template>
  <div class="root flex_center">
    <div class="swiper_con">
      <div class="title_box flex_between_align_center">
        <Title :titleName="titleName"></Title>
        <div class="right">
          <p>已帮助完成出国服务</p>
          <div class="relative">
            <span
              class="num_card flex_center"
              :key="index"
              v-for="(item, index) in personNumArr"
              >{{ item }}</span
            >
            <span class="unit">人</span>
          </div>
        </div>
      </div>
      <p class="text flex_align">
        <b>{{ menuNum.text }}</b>
        <span>已成功为{{ menuNum.num }}人实现美国留学愿望</span>
      </p>
      <div class="menu_box flex_between">
        <!-- 左侧目录 -->
        <div class="menu_left">
          <swiper
            ref="menuSwiper"
            :options="menuSwiperOptions"
            v-if="menuSwiperShow"
          >
            <swiper-slide :key="index" v-for="(item, index) in menuList">{{
              item
            }}</swiper-slide>
          </swiper>
        </div>
        <!-- 右侧内容 -->
        <div class="tab_con relative">
          <div id="case_data_chart" ref="chart" v-if="chartShow"></div>
          <div class="hide_chart_logo"></div>
        </div>
      </div>
      <Arrow></Arrow>
    </div>
  </div>
</template>
<script>
import Title from "../title";
import Arrow from "../arrow";
import * as echarts from "echarts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getCase } from "@/api/home.js";
import { Bus } from "@/views/home/components/bus.js";
let vm = null;
export default {
  name: "",
  components: {
    Title,
    Arrow,
  },
  props: ["swiperName"],
  data() {
    return {
      chartShow: true,
      allData: [],
      titleName: {
        chinese: "海师成功案例数据",
        english: "THE CASE DATA",
      },
      menuNum: {
        text: "",
        num: "",
        service_id: "",
      },
      menuActiveIndex: 0,
      menuList: [],
      //左侧目录swiper
      menuSwiperOptions: {
        slidesPerView: 5,
        spaceBetween: 30,
        centeredSlides: true,
        direction: "vertical",
        mousewheel: true,
        touchMoveStopPropagation: true,
        slideToClickedSlide: true,
        loop: true,
        on: {
          slideChange: function (swiper, event) {
            vm.chartData = vm.allData.service[this.realIndex].data;
            vm.initChart();
            vm.menuNum = {
              text: vm.menuList[this.realIndex],
              num: vm.allData.service[this.realIndex].count_sum,
              service_id: vm.allData.service[this.realIndex].id,
            };
          },
        },
      },
      menuSwiperShow: false,
      personNum: "",
      personNumArr: [],
      chartColor: [
        "#f3bc70",
        "#f4c079",
        "#f4c380",
        "#f4c584",
        "#f4c686",
        "#f4c07a",
        "#f4c17c",
      ],
      chartData: [
        {
          title: "TOP3",
          value: 500,
          subData: [
            { name: "大学1", value: 200 },
            { name: "大学2", value: 150 },
            { name: "大学3", value: 100 },
            { name: "大学4", value: 50 },
          ],
        },
        {
          title: "TOP10",
          value: 500,
          subData: [
            { name: "大学1", value: 200 },
            { name: "大学2", value: 150 },
            { name: "大学3", value: 100 },
            { name: "大学4", value: 50 },
          ],
        },
      ],
    };
  },
  watch: {
    // 监听父组件swiper变化，重新渲染图表
    swiperName(val) {
      val === "caseData" ? this.renderChart(true) : this.renderChart(false);
    },
  },
  created() {
    vm = this;
  },
  beforeMount() {},
  mounted() {
    // 获取数据
    getCase().then((res) => {
      if (res.code === 200) {
        let resData = res.data;
        this.allData = res.data;
        // menu数据
        resData.service.forEach((item) => {
          this.menuList.push(item.title);
        });
        this.menuNum = {
          text: this.menuList[this.menuActiveIndex],
          num: this.allData.service[this.menuActiveIndex].count_sum,
          service_id: this.allData.service[this.menuActiveIndex].id,
        };
        //chart数据
        this.chartData = resData.service[0].data;
        this.personNumArr = resData.case_sum.toString().split("");
        this.menuSwiperShow = true;
        // 渲染图表
        this.$nextTick(() => {
          vm.initChart();
        });
      }
    });
  },
  methods: {
    // 初始化图表
    initChart() {
      am4core.useTheme(am4themes_animated);
      //多个图需要先创建容器
      let rank = "";
      let container = am4core.create(this.$refs.chart, am4core.Container);
      //container 整个容器
      container.width = am4core.percent(100);
      container.height = am4core.percent(100);
      container.layout = "horizontal";
      //为容器创建第一个子图表
      let chart = container.createChild(am4charts.PieChart);
      chart.width = am4core.percent(65);
      chart.radius = am4core.percent(65);
      // 添加数据
      this.chartData.forEach((item, index) => {
        item.color = am4core.color(this.chartColor[index]);
      });
      chart.data = this.chartData;
      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());

      pieSeries.dataFields.value = "value";
      pieSeries.dataFields.category = "title";
      // 模块分割线
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeWidth = 3;
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.slices.template.tooltipText =
        "[#fff]{category}: {value.percent.formatNumber('#.#')}%  ({value}人)";
      // pieSeries.legendSettings.labelText = "[{color}]{value}人成功考入全美排名 {name}[/]学院";
      pieSeries.legendSettings.labelText =
        "[ #62472c '12px']{value}（{value.percent.formatNumber('#.#')}%）人成功考入全美排名 {name}学院[/]";
      // 填充颜色
      pieSeries.slices.template.propertyFields.fill = "color";
      // 文字不环绕显示饼图
      // pieSeries.alignLabels = false;
      // 不显示线
      // pieSeries.ticks.template.disabled = true;
      // 标签与圆周围的基本位置之间的距离。
      // pieSeries.labels.template.radius = am4core.percent(-40);
      pieSeries.labels.template.fill = am4core.color("#666");
      pieSeries.slices.template.states.getKey(
        "active"
      ).properties.shiftRadius = 0;
      pieSeries.labels.template.text =
        "{category}\n{value.percent.formatNumber('#.#')}%";

      pieSeries.slices.template.events.on("hit", function (event) {
        selectSlice(event.target.dataItem);
      });
      // 动画效果
      pieSeries.hiddenState.properties.endAngle = -90;
      let chart2 = container.createChild(am4charts.PieChart);
      chart2.width = am4core.percent(55);
      chart2.radius = am4core.percent(55);
      // Add and configure Series
      let pieSeries2 = chart2.series.push(new am4charts.PieSeries());
      pieSeries2.dataFields.value = "value";
      pieSeries2.dataFields.category = "name";
      pieSeries2.slices.template.states.getKey(
        "active"
      ).properties.shiftRadius = 0;
      // 图例文字
      pieSeries2.legendSettings.labelText = "";
      // 显示文字
      pieSeries2.slices.template.tooltipText = "[#fff]{category}: ({value}人)";
      pieSeries2.labels.template.disabled = true;
      pieSeries2.ticks.template.disabled = true;
      pieSeries2.alignLabels = false;
      pieSeries2.events.on("positionchanged", updateLines);
      pieSeries2.slices.template.events.on("hit", function (event) {
        let val = "haishi/haishiCase";
        vm.openNewTab({
          name: val,
          query: {
            title: vm.menuNum.text,
            rank,
            service_id: vm.menuNum.service_id,
          },
        });
        // vm.$router.push(
        //   `/haishi/haishiCase?title=${vm.menuNum.text}&rank=${rank}&service_id=${vm.menuNum.service_id}`
        // );
      });
      // 动画效果
      pieSeries.hiddenState.properties.endAngle = -90;
      let interfaceColors = new am4core.InterfaceColorSet();
      let line1 = container.createChild(am4core.Line);
      line1.strokeDasharray = "2,2";
      line1.strokeOpacity = 0.5;
      line1.stroke = interfaceColors.getFor("alternativeBackground");
      line1.isMeasured = false;
      let line2 = container.createChild(am4core.Line);
      line2.strokeDasharray = "2,2";
      line2.strokeOpacity = 0.5;
      line2.stroke = interfaceColors.getFor("alternativeBackground");
      line2.isMeasured = false;

      let selectedSlice;

      function selectSlice(dataItem) {
        rank = dataItem.properties.category; //跳转页面传递参数
        selectedSlice = dataItem.slice;
        let fill = selectedSlice.fill;
        let count = dataItem.dataContext.subData.length;
        pieSeries2.colors.list = [];
        for (var i = 0; i < count; i++) {
          pieSeries2.colors.list.push(fill.brighten((i * 2) / count));
        }

        chart2.data = dataItem.dataContext.subData;
        pieSeries2.appear();

        let middleAngle = selectedSlice.middleAngle;
        let firstAngle = pieSeries.slices.getIndex(0).startAngle;
        let animation = pieSeries.animate(
          [
            { property: "startAngle", to: firstAngle - middleAngle },
            { property: "endAngle", to: firstAngle - middleAngle + 360 },
          ],
          600,
          am4core.ease.sinOut
        );
        animation.events.on("animationprogress", updateLines);
        selectedSlice.events.on("transformed", updateLines);
      }

      function updateLines() {
        if (selectedSlice) {
          let p11 = {
            x:
              selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle),
            y:
              selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle),
          };
          let p12 = {
            x:
              selectedSlice.radius *
              am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc),
            y:
              selectedSlice.radius *
              am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc),
          };

          p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
          p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);

          let p21 = { x: 0, y: -pieSeries2.pixelRadius };
          let p22 = { x: 0, y: pieSeries2.pixelRadius };

          p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
          p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);

          line1.x1 = p11.x;
          line1.x2 = p21.x;
          line1.y1 = p11.y;
          line1.y2 = p21.y;

          line2.x1 = p12.x;
          line2.x2 = p22.x;
          line2.y1 = p12.y;
          line2.y2 = p22.y;
        }
      }

      chart.events.on("datavalidated", function () {
        setTimeout(function () {
          selectSlice(pieSeries.dataItems.getIndex(0));
        }, 1000);
      });
    },
    // 重新渲染图表
    renderChart(val) {
      this.chartShow = val;
      if (val) {
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },
    caseSwiperSlideTo(val) {
      let outIndex = 1;
      this.menuList.forEach((item, index) => {
        if (item === val) {
          outIndex = index;
        }
      });
      // this.menuSwiper.slideTo(outIndex, 1000, false);
      this.menuSwiper.slideTo(outIndex, 1000, false);
    },
    // 新tab
    openNewTab(to) {
      const routeData = this.$router.resolve(to);
      window.open(
        `#/${to.name}?title=${to.query.title}&rank=${to.query.rank}&service_id=${to.query.service_id}`,
        "_blank"
      );
    },
  },
  computed: {
    menuSwiper() {
      return this.$refs.menuSwiper.swiper;
    },
  },
};
</script>
<style lang='scss' scoped>
div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.root {
  background: url(./image/bg.png) no-repeat;
  background-size: (100% 100%);
  height: 100%;
}
.title_box .right {
  letter-spacing: 1px;
  color: #d99d66;
  p {
    margin-bottom: 10px;
  }
  .num_card {
    display: inline-flex;
    background: #d99d66;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    margin-right: 5px;
    padding: 0px 4px 2px 4px;
  }

  .unit {
    font-size: 14px;
    position: absolute;
    bottom: 0;
  }
}
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex_between_align_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.relative {
  position: relative;
}

.swiper_con {
  width: 1200px;
  height: 850px;
  position: relative;
}

.listIcon {
  margin-left: 10px;
  font-size: 18px;
}
.text {
  color: #666;
  margin-top: 65px;
  display: flex;
  align-items: center;
  b {
    color: #333;
    font-size: 20px;
    margin-right: 10px;
  }
}

.menu_box {
  margin-top: 46px;
  align-items: center;
}

// 左侧目录swiper
.menu_left {
  height: 400px;
  .swiper-container {
    height: 100%;
  }
  /deep/ .swiper-slide {
    cursor: pointer;
  }
  /deep/ .swiper-container {
    // padding: 50px 0;
  }
  /deep/ .swiper-slide-active {
    color: #d99d66;
    position: relative;
    font-weight: 600;
  }
  /deep/ .swiper-slide {
    padding: 20px;
    text-align: left;
  }
  /deep/ .swiper-slide-active:before {
    content: "";
    display: block;
    width: 4px;
    height: 17px;
    background: #d99d66;
    position: absolute;
    left: 10px;
    top: 22px;
    margin-right: 10px;
  }
}
// 右侧内容
.tab_con {
  width: 951px;
  #case_data_chart {
    height: 617px;
    // background: red;
  }
}

// 隐藏amcharts logo
.hide_chart_logo {
  width: 70px;
  height: 25px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
