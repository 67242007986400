<template>
  <div>
      <p class="chinese">{{titleName.chinese}}</p>
       <p class="english">{{titleName.english}}</p>
  </div>
</template>
<script>
  export default {
    name:'',
    components: {},
    props:{
      titleName:{

      }
    },
    data () {
      return {

      };
    },
    watch: {},
    created() {},
    beforeMount() {},
    mounted() {},
    methods: {},
    computed: {}
  }
</script>
<style lang='scss' scoped>

.chinese {
  font-size: 36px;
  font-weight: 400;
  color: #333333;
  letter-spacing: 2px;
  text-align: left;
}
.english {
  font-size: 26px;
  font-weight: 400;
  color: #cccccc;
  text-align: left;
  margin-top: 10px;
}
</style>
