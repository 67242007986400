<template>
  <div class="item">
    <div class="tool_box flex_between_align_center">
      <div class="info flex_align">
        <div class="block avatar">
          <el-avatar
            shape="square"
            :fit="fit"
            :size="50"
            :src="comment.hwds.hstp"
          ></el-avatar>
        </div>
        <span>{{ comment.hwds.name }}</span>
      </div>
      <div class="tool flex_align">
        <!-- <div class="btn blue play_btn flex_align" @click="playVideo(comment.url)">
          <i class="iconfont xhgplay"></i>
          播放
        </div> -->
        <!-- <div class="btn yellow flex_align">
          <i class="iconfont xhgshare"></i>
          分享
        </div> -->
      </div>
    </div>
    <div class="comment_box teacher">
      <div class="star_box flex_align">
        <span class="title">老师评价</span>
        <el-rate v-model="comment.teacher.star" :disabled="true" disabled-void-color=""></el-rate>
      </div>
      <p class="comment_text">
        {{ comment.teacher.content }}
      </p>
    </div>
    <div class="comment_box">
      <div class="star_box flex_align">
        <span class="title">学生评价</span>
        <el-rate v-model="comment.student.star" :disabled="true"></el-rate>
      </div>
      <p class="comment_text">
        {{ comment.student.content }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {
    comment: {},
  },
  data() {
    return {
      fit: "scale-down",
    };
  },
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {},
  methods: {
    playVideo(url) {
      this.$emit("playVideo",url);
    },
  },
  computed: {},
};
</script>
<style lang='scss' scoped>
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex_between_align_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.relative {
  position: relative;
}

.item {
  // width: 590px;
  height: 350px;
  background:white;
  padding: 20px 32px 30px 20px;
  background: url(../image/itemBg.png) no-repeat;
  background-size: 100%;
}
.avatar {
  margin-right: 10px;
}
.comment_box {
  .star_box {
    margin-bottom: 10px;
    .title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin-right: 30px;
    }
  }
}
.comment_box.teacher {
  margin-bottom: 30px;
}
.comment_text {
  color: #666666;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.tool_box {
  margin-bottom: 44px;

  .tool {
    i {
      font-size: 16px;
      margin-right: 5px;
    }
    .btn {
      padding: 7px;
      cursor: pointer;
          font-size: 16px;
      &.play_btn {
        margin-right: 20px;
      }
      &.blue {
        border-radius: 6px;
        border: 1px solid #1890ff;
        color: #1890ff;
      }
      &.yellow {
        border-radius: 6px;
        border: 1px solid #d99d66;
        color: #d99d66;
      }
    }
  }
}
.video_box {
  width: 300px;
  height: 300px;
}
/deep/ .el-avatar{
  background:none;
}
</style>
