<template>
  <div class="root flex_center">
    <div class="swiper_con">
      <div class="title_box flex_between">
        <Title :titleName="titleName"></Title>
        <div class="right flex_align animated fadeIn" @click="toPath('haishi')">
          优选海师 <i class="el-icon-caret-right listIcon"></i>
        </div>
      </div>

      <div class="menu_box">
        <!-- tab 目录 -->
        <div class="menu">
          <swiper :options="menuSwiperOptions">
            <swiper-slide v-for="(item, index) in menuList" :key="index">
              <span
                class="item"
                :class="{ active: menuActiveIndex === index }"
                >{{ item.title }}</span
              >
            </swiper-slide>
          </swiper>
        </div>
        <!-- tab 内容 -->
        <div class="swiper_box">
          
          <swiper ref="conSwiper" :options="conSwiperOptions" class="swiper">
            <swiper-slide
              class="tab_con flex_between"
              v-for="(item, index) in menuList"
              :key="index"
            >
              <div class="text_box">
                <p class="title" @click="toCaseData()">{{ item.title }}</p>
                <p class="text">
                  {{item.info}}
                  <!-- {{ substringStr(item.isShowMore, item.info) }} -->
                  <!-- <span v-if="!item.isShowMore">...</span
                  ><span
                    class="more_btn"
                    @click.stop="showMore(index)"
                    v-if="!item.isShowMore"
                    >更多</span
                  > -->
                </p>
              </div>
              <div class="shadow" >
                <div class="video_box">
                  <video :poster="item.url"
                    v-if="item.type !== 0"
                    :src="item.url_video"
                    style="width: 400px; height: 400px"
                  ></video>
                  <el-image
                    v-else
                    style="width: 100%; height: 100%"
                    :src="item.url"
                    fit="fit"
                  ></el-image>
                  <div
                    class="mask flex_center"
                    v-if="item.type !== 0"
                    @click="playVideo(item.url_video, true)"
                  >
                    <div class="btn flex_center">
                      <i class="iconfont xhgvideo-play"></i>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div
            class="serviceSwiperswiper swiper-button-prev"
            slot="button-prev"
          ></div>
          <div
            class="serviceSwiperswiper swiper-button-next"
            slot="button-next"
          ></div>
        </div>
      </div>
      <!-- 箭头动画 -->
      <Arrow></Arrow>
    </div>
    <!-- 视频播放弹窗 -->
    <VideoDialog ref="videoDialog" type="service"></VideoDialog>
  </div>
</template>
<script>
import Title from "../title";
import Arrow from "../arrow";
import commonMeth from "../../commonMeth/index";
import { getService } from "@/api/home.js";
import { Bus } from "@/views/home/components/bus.js";
import VideoDialog from "@/views/home/meiyan/components/videoDialog";
let vm = null;
export default {
  name: "",
  components: {
    Title,
    Arrow,
    VideoDialog,
  },
  props: [""],
  data() {
    return {
      titleName: {
        chinese: "海师提供的服务",
        english: "SERVICE",
      },
      menuList: [
        //目录列表
        { id: 1, title: "头脑风暴", info: "", type: 0, isShowMore: false },
      ],
      menuActiveIndex: 0, //目录当前index
      menuSwiperOptions: {
        //目录swiper
        slidesPerView: "auto",
        loop: false,
        mousewheel: false,
        on: {
          click: function (e, a) {
            vm.menuActiveIndex = this.clickedIndex;
            vm.conSwiper.slideTo(this.clickedIndex, 1000, false);
          },
          slideChange() {
          },
        },
      },
      conSwiperOptions: {
        //内容swiper
        slidesPerView: 1,
        navigation: {
          nextEl: ".serviceSwiperswiper.swiper-button-next",
          prevEl: ".serviceSwiperswiper.swiper-button-prev",
        },
        loop: false,
        mousewheel: false,
        on: {
          slideChange: function (swiper, event) {
            vm.menuActiveIndex = this.activeIndex;
          },
        },
      },
      videoUrl: "", //视频地址
      fit: "cover",
    };
  },
  watch: {},
  created() {
    vm = this;
  },
  beforeMount() {},
  mounted() {
    this.conSwiper.slideTo(0, 1000, false);
    // 获取数据
    getService().then((res) => {
      if (res.code === 200) {
        this.menuList = res.data;
        this.menuList.forEach((item) => {
          if (item.info.length > 140) item.isShowMore = false;
          else item.isShowMore = true;
        });
      }
    });
  },

  methods: {
    // 目录点击
    menuClick(index) {
      this.conSwiper.slideTo(index, 1000, false);
    },
    // 跳转页面
    toPath(val) {
      // 公共方法
      commonMeth.openNewTab(({name:val}));
    },
    // 跳转到成功案例swiper
    toCaseData() {
      let menuText = "";
      menuText = this.menuList[this.menuActiveIndex].title;
      Bus.$emit("toCaseData", menuText);
    },
    // 更多按钮
    showMore(outIndex) {
      this.menuList.forEach((item, index) => {
        if (outIndex === index) {
          item.isShowMore = !item.isShowMore;
        }
      });
      this.menuList.push({ title: "测试", info: "测试" });
      this.menuList.splice(this.menuList.length - 1, 1);
    },
    playVideo(url) {
      this.$refs.videoDialog.show(url);
    },
  },
  computed: {
    // 内容swiper
    conSwiper() {
      return this.$refs.conSwiper.swiper;
    },
    // 截取内容字数
    substringStr() {
      return (val, info) => {
        let str = val ? info : info.substring(0, 140);
        return str;
      };
    },
  },
};
</script>
<style lang='scss' scoped>
div,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.root {
  background: url(./image/swiperTwoBg.png) no-repeat;
  background-size: (100% 100%);
  height: 100%;
}
.title_box .right {
  letter-spacing: 1px;
  cursor: pointer;
}
.flex_box {
  display: flex;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex_align {
  display: flex;
  align-items: center;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}

.swiper_con {
  width: 1200px;
  height: 850px;
  position: relative;
}

.listIcon {
  margin-left: 10px;
}

.menu_box {
  margin-top: 62px;
  position: relative;
  // tab 目录
  .menu {
    .swiper-slide {
      width: auto;
      cursor: pointer;
    }
    .swiper-container {
      padding-bottom: 20px;
    }
    .item {
      margin: 0 20px;
      padding-bottom: 10px;
      border-bottom: 3px solid transparent;
      white-space: nowrap;
      &.active {
        color: #d99d66;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 3px solid #d99d66;
      }
    }
  }
  // tab 内容
  .swiper_box {
    margin-top: 82px;
    .swiper-container {
      padding-bottom: 20px;
    }
    .tab_con {
      .text_box {
        width: 524px;
            margin-top: 30px
        // cursor: pointer;
      }
      .title {
        font-size: 36px;
        font-weight: 500;
        color: #333333;
        cursor: pointer;
      }
      .text {
        margin-top: 30px;
        color: #666666;
        line-height: 32px;
        height: 300px;
        overflow: auto;
      }
      .more_btn {
        color: #175199;
        cursor: pointer;
      }

      .mask {
        width: 385px;
        height: 385px;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 15px;
        border-radius: 10px 40px 10px 50px;
        .btn {
          width: 82px;
          height: 82px;
          border-radius: 50%;
          border: 3px solid rgba(255, 255, 255, 0.3);
          cursor: pointer;
          i {
            font-size: 30px;
            color: rgba(255, 255, 255, 0.5);
            margin-left: 6px;
          }
        }
      }
      .shadow.video {
        background: linear-gradient(180deg, #f8e2c4 0%, #f3bc70 100%);
        .video_box {
          width: 400px;
          height: 400px;
          border-radius: 10px 40px 10px 50px;
          position: absolute;
          top: 15px;
          left: -15px;
          overflow: hidden;
          top: 15px;
        }
      }
      .shadow {
        position: relative;
        width: 400px;
        height: 400px;
        border-radius: 10px 40px 10px 50px;
        .video_box {
          width: 415px;
          height: 415px;
          border-radius: 10px 40px 10px 50px;
          position: absolute;

          left: -15px;
          overflow: hidden;
        }
      }
    }
  }
}

// tab样式修改

// swiper箭头
// 右箭头
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  font-family: iconfont !important;
  font-size: 26px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e6c9";
  color: #333;
}
// 左箭头
.swiper-button-prev[data-v-fbba7dfa]:after,
.swiper-container-rtl .swiper-button-next[data-v-fbba7dfa]:after {
  font-size: 26px;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  font-family: iconfont !important;
  font-size: 26px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e6c8";
  color: #333;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  color: #333;
}
.swiper-button-prev,
.swiper-button-next {
  top: 90%;
  bottom: 0;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  left: 95px;
}

.swiper-button-prev,
.swiper-button-next {
  width: 60px;
  height: 60px;
  border-radius: 8px 16px 8px 16px;
  border: 2px solid transparent;
  transition: 0.3s;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  border: 2px solid #000;
}
.swiper-button-disabled {
  color: red !important;
}
// swiper箭头结束

// dialog中按钮样式
.dialog_btn,
.dialog_btn:hover {
  background: linear-gradient(270deg, #f3bc70 0%, #f8e2c4 100%);
  border: none;
  color: #935d14;
}
</style>
